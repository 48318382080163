import React, { Component } from 'react'
import axios from 'axios';
const API_PATH = 'https://getform.io/f/7c10ffac-26af-4020-a725-e84ad40a7761';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			name: '',
			message: '',
			mailSent: false,
			error: null
		}
	}
	handleFormSubmit(event) {
		event.preventDefault();
		axios({
			method: 'post',
			url: `${API_PATH}`,
			headers: { 'content-type': 'application/json' },
			data: { email: this.state.email, name: this.state.name, message: this.state.message }
		})
			.then(result => {
				this.setState({
					mailSent: result.data

				})
			})
			.catch(error => this.setState({ error: error.message }));
		this.setState({ name: '', lastname: '', email: '', message: '', mailSent: false, error: null })
	}
	render() {
		return (
			<footer className="footer">
				<div className="container">
					<div className="row">
						<div className="footer-widgets">
							<div className="col-md-4 col-sm-6">
								<div className="widget widget_text">
									<h4 className="widget-title">About Us</h4>
									<div className="textwidget">
										<img src="assets/images/whitelogo.png" alt="images" />
										<p>
											"TF Logistics LLC, headquartered in Verona, Kentucky is a 3rd Party Logistics provider. We provide solutions for general products by specializing in FTL, LTL, flatbeds, and expediated shipping.
										</p>
									</div>
									<div className="widget widget_socials">
										<div className="socials">
											<a className="facebook" target="_blank" href="https://www.facebook.com/profile.php?id=100085025820212"><i className="fa fa-facebook"></i></a>
											<a className="twitter" target="_blank" href="https://www.instagram.com/tflogisticsllc20/"><i className="fa fa-instagram"></i></a>

										</div>
									</div>

								</div>
							</div>
							<div className="col-md-4 col-sm-6">
								<div className="widget widget_text">
									<h4 className="widget-title">Powered by</h4>
									<div className="textwidget">
										<img src="/assets/images/TGFlogotransp.png" alt="images" />
										<p>
											"TF Logistics LLC is powered by Tallgrass Freight co. We are proud to be a part of Tallgrass Freight co.
										</p>
									</div>


								</div>
							</div>
							<div className="col-md-4 col-sm-6">
								<div className="widget widget-contact">
									<h4 className="widget-title">Contact From</h4>
									<form action="#" method="post" acceptCharset="utf-8" onSubmit={e => this.handleFormSubmit(e)}>
										<div className="form-ft">
											<div className="flat-wrap-field">
												<div className="flat-one-half">
													<input type="text" name="name" placeholder="Name" required=""
														value={this.state.name}
														onChange={e => this.setState({ name: e.target.value })} />
												</div>
												<div className="flat-one-half">
													<input type="email" id="email" name="email" placeholder="Your email"
														required
														value={this.state.email}
														onChange={e => this.setState({ email: e.target.value })} />
												</div>
											</div>
											<p>
												<textarea name="your-message" cols="40" rows="10" placeholder="Message"
													value={this.state.message}
													onChange={e => this.setState({ message: e.target.value })}></textarea>
											</p>
											<p>
												<button type="submit" className="btn-contact-ft"
													value="Submit">Send Message</button>
											</p>
											{this.state.mailSent ?
												<div className="alert alert-success" role="alert" style={{ marginTop: "10px", fontWeight: "500", zIndex: "1000", backgroundColor: "white", color: "#18364a" }}>
													Thank you for contacting TF logistics LLC. We will get back to you as soon as possbile!
												</div>
												: null
											}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	}
}
export default Footer