import React, { Component } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
const API_PATH = 'https://getform.io/f/7c10ffac-26af-4020-a725-e84ad40a7761';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            lastname: '',
            message: '',
            mailSent: false,
            submitting: false,
            error: null,
            verified: false
        }
    }
    componentDidMount() {
        this.handleFormSubmit = (event) => {
            event.preventDefault();
            axios({
                method: 'post',
                url: `${API_PATH}`,
                headers: { 'content-type': 'application/json' },
                data: { email: this.state.email, name: this.state.name, lastname: this.state.lastname, message: this.state.message }
            })
                .then(result => {
                    this.setState({
                        mailSent: true,
                    })
                })
                .catch(error => this.setState({ error: error.message }));
            this.setState({ name: '', lastname: '', email: '', message: '', mailSent: false, error: null, verified: false })
        }
    }
    // onChangeRecap(value) {
    //         this.setState(prevState=> {
    //             return{
    //             verified: !prevState.verified}}
    //         )
    //   }

    render() {

        let submit_button = <button type="submit" className="btn btn-primary btn-modern" data-loading-text="Loading...">Send Message</button>

        return (
            <div className="col-sm-8" style={{ display: this.mailSent ? "none" : "block" }}>
                <div className="contact-form" >
                    <p>Contact Me</p>
                    <div>
                        <form action="#" onSubmit={e => this.handleFormSubmit(e)} >
                            <label>First Name</label>
                            <input type="text" id="name" name="name" placeholder="Your name.."
                                value={this.state.name}
                                onChange={e => this.setState({ name: e.target.value })}
                            />
                            <label>Last Name</label>
                            <input type="text" id="lastname" name="lastname" placeholder="Your last name.."
                                value={this.state.lastname}
                                onChange={e => this.setState({ lastname: e.target.value })}
                            />

                            <label>Email*</label>
                            <input type="email" id="email" name="email" placeholder="Your email" required
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })} />

                            <label>Message</label>
                            <textarea id="message" name="message" placeholder="Type your message ..."
                           
                                value={this.state.message}
                                onChange={e => this.setState({ message: e.target.value })}></textarea>

                            {/* <ReCAPTCHA
                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                onChange={el=>this.onChangeRecap(el)}
                            /> */}
                            {this.state.mailSent ?
                                <div className="alert alert-success" role="alert" style={{ marginTop: "10px", fontWeight: "500" }}>
                                    Thank you for contacting TF logistics LLC. We will get back to you as soon as possbile!
                                </div>
                                : null
                            }
                            {submit_button}
                            <div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default Form