import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import axios from 'axios';
const API_PATH = 'https://getform.io/f/7c10ffac-26af-4020-a725-e84ad40a7761';

class Quote extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			name: '',
			lastname: '',
			phone_number: '',
			company: '',
			pickup_address: '',
			delivery_address: '',
			trailer_type: '',
			message: '',
			mailSent: false,
			error: null
		}
	}

	handleFormSubmit(event) {
		event.preventDefault();
		axios({
			method: 'post',
			url: `${API_PATH}`,
			headers: { 'content-type': 'application/json' },
			data: {
				email: this.state.email, name: this.state.name, phone_number: this.state.phone_number,
				company: this.state.company, pickup_address: this.state.pickup_address, delivery_address: this.state.delivery_address,
				trailer_type: this.state.trailer_type, message: this.state.message
			}
		})
			.then(result => {
				this.setState({
					mailSent: result.data
				})
			})
			.catch(error => this.setState({ error: error.message }));
		this.setState({ name: '', lastname: '', email: '', message: '', mailSent: false, error: null })
	}
	render() {
		return (
			<div>
				<section className="page-title style1">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="breadcrumbs">
									<h1 className="page-title-heading">Request Quote</h1>
									<ul className="trail-items">
										<li>You are here:</li>
										<li className="trail-item">
											<Link to="/">
												<span title=""> Home</span>
											</Link>
											<span> > </span>
										</li>
										<li className="trail-end">
											Quote
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="main-about page-wrap">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="content-area">
									<div className="post-wrap">
										<article className="about-post style4">
											<div className="entry-content">
												<form action="#" method="get" acceptCharset="utf-8" onSubmit={e => this.handleFormSubmit(e)}>
													<div className="flat-form-quote style1">
														<div className="title-quote">
															Quotation request
														</div>
														<p>Thank you for your interest in TF Logistics LLC. We are excited to help you with your 3PL needs. Please complete the form below and a member of our team will reach out to you as soon as possible. </p>
														<p>
															<label>
																Your name *
																<span>
																	<input type="text" name="name" placeholder="You Name" required
																		value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
																</span>
															</label>
															<label>
																Company name *
																<span>
																	<input type="text" name="company" placeholder="Company name" required
																		value={this.state.company} onChange={e => this.setState({ company: e.target.value })} />
																</span>
															</label>
															<label>
																Phone number *
																<span>
																	<input type="text" name="phone_number" placeholder="123-456-7890" required
																		value={this.state.phone_number} onChange={e => this.setState({ phone_number: e.target.value })} />
																</span>
															</label>
															<label>
																Your email *
																<span>
																	<input type="text" name="email" placeholder="You email" required
																		value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
																</span>
															</label>
															<label>
																Pick-up address information
																<span>Country, City, Postal code *</span>
																<span>
																	<input type="text" name="pickup_address" placeholder="Country" required
																		value={this.state.pickup_address} onChange={e => this.setState({ pickup_address: e.target.value })} />
																</span>
															</label>
															<label>
																Delivery address information
																<span>Country, City, Postal code *</span>
																<span>
																	<input type="text" name="delivery_address" placeholder="Country" required
																		value={this.state.delivery_address} onChange={e => this.setState({ delivery_address: e.target.value })} />
																</span>
															</label>
															<label>
																What mode of transport do you require?
																<span>
																	<select name="trailer_type"
																		value={this.state.trailer_type} onChange={e => this.setState({ trailer_type: e.target.value })} required>
																		<option value="" disabled selected hidden>Trailer type</option>
																		<option defaultValue="LTL">LTL</option>
																		<option defaultValue="Partial">Partial</option>
																		<option defaultValue="Van">Van</option>
																		<option defaultValue="Reefer">Reefer</option>
																		<option defaultValue="Flatbed">Flatbed</option>
																	</select>
																</span>
															</label>
														</p>
														<div className="title-form">Description of the goods</div>
														<p>
															Please supply us with information about the goods you want to ship. Number of pallets / packages, dimensions, weight. Also if possible information on the desired loading and unloading date and time or any other requests which we need to take into account.
														</p>
														<div className="title-form">Goods information *</div>
														<p>
															<span>
																<textarea name="message" cols="40" rows="10"
																	value={this.state.message} onChange={e => this.setState({ message: e.target.value })}></textarea>
															</span>
															{this.state.mailSent ?
																<div className="alert alert-success" role="alert" style={{ marginTop: "10px", fontWeight: "500" }}>
																	Thank you for contacting TF logistics LLC. We will get back to you as soon as possbile!
																</div>
																: null
															}
															<button type="submit" className="button-form-quote"
																value="Submit">Send Reuqest</button>
														</p>
													</div>
												</form>
											</div>
										</article>
									</div>
								</div>
							</div>
						</div>
					</div >
				</section >
			</div >
		)
	}
}
export default Quote