import React, { Component } from 'react';
import axios from 'axios';

const API_PATH = 'https://getform.io/f/7c10ffac-26af-4020-a725-e84ad40a7761';


class RequestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            phone_number: '',
            departure: '',
            arrival: '',
            trailer_type: '',
            mailSent: false,
            error: null
        }
    }
    handleFormSubmit(event) {
        event.preventDefault();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                email: this.state.email, name: this.state.name, phone_number: this.state.phone_number,
                departure: this.state.departure, arrival: this.state.arrival, trailer_type: this.state.trailer_type
            }
        })
            .then(result => {
                this.setState({
                    mailSent: result.data
                })
            })
            .catch(error => this.setState({ error: error.message }));
        console.log(this.state);
        this.setState({ name: '', lastname: '', email: '', message: '', mailSent: false, error: null })
    }
    render() {
        return (
            <section className="flat-row flat-form-qoute parallax parallax1" id="quote">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <form action="./contact/contact-process.php" method="get" acceptCharset="utf-8"  onSubmit={e => this.handleFormSubmit(e)}>
                                <div className="form-qoute">
                                    <div className="form-qoute-title center">
                                        Request For Quote
                                    </div>
                                    <div className="desc-qoute">
                                        Request your shipment quote and we will be in touch with you in the shortest time possible.
                                    </div>
                                    <div className="flat-wrap-form">
                                        <div className="flat-wrap-input">
                                            <input type="text" name="name" size="40" aria-required="true" aria-invalid="false" placeholder="You Name"
                                                value={this.state.name}
                                                onChange={e => this.setState({ name: e.target.value })} />
                                        </div>
                                        <div className="flat-wrap-input">
                                            <input type="email" name="email" size="40" aria-required="true" aria-invalid="false" placeholder="You Email"
                                            required value={this.state.email}
                                                onChange={e => this.setState({ email: e.target.value })} />
                                        </div>
                                        <div className="flat-wrap-input">
                                            <input type="text" name="yourphone" size="40" aria-invalid="false" placeholder="You phone number"
                                                value={this.state.phone_number}
                                                onChange={e => this.setState({ phone: e.target.value })}
                                            />
                                        </div>
                                        <div className="flat-wrap-input">
                                            <input type="text" name="departure" size="40" aria-invalid="false" placeholder="Departure"
                                                value={this.state.departure}
                                                onChange={e => this.setState({ departure: e.target.value })}
                                            />
                                        </div>
                                        <div className="flat-wrap-input">
                                            <input type="text" name="arrival" size="40" aria-invalid="false" placeholder="Arrival"
                                                value={this.state.arrival}
                                                onChange={e => this.setState({ arrival: e.target.value })} />
                                        </div>
                                        <div className="flat-wrap-input" id="flat-color">
                                            <select name="trailer_type" aria-invalid="false" placeholder="trailer type"
                                                value={this.state.trailer_type}
                                                onChange={e => this.setState({ trailer_type: e.target.value })}
                                            >
                                                <option disabled selected hidden>Trailer type</option>
                                                <option defaultValue="LTL">LTL</option>
                                                <option defaultValue="Partial">Partial</option>
                                                <option defaultValue="Van">Van</option>
                                                <option defaultValue="Reefer">Reefer</option>
                                                <option defaultValue="Flatbed">Flatbed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <p className="center">
                                        <button type="submit" className="button-form-qoute"
                                        value="Submit">Get A Quote</button>
                                    </p>
                                            {this.state.mailSent ?
                                                <div className="alert alert-success" role="alert" style={{ marginTop: "10px", fontWeight: "500", zIndex: "1000", backgroundColor: "white", color: "#18364a" }}>
                                                    Thank you for contacting TF logistics LLC. We will get back to you as soon as possbile!
                                                </div>
                                                : null
                                            }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="overlay"></div>
            </section>
        )
    }
}

export default RequestForm